import { default as _91slug_93x27wRKIh4sMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/i/frame/[slug].vue?macro=true";
import { default as indexJh64adwDDGMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/index.vue?macro=true";
import { default as indexKKivseUm8KMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/journey/index.vue?macro=true";
import { default as _91id_93mlQoBHsm6qMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/journey/shared/[id].vue?macro=true";
import { default as _91slug_9392ad5lSA4eMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[slug].vue?macro=true";
import { default as _91bugReportQrCode_93AvNZXFv3gTMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/bug_report/[bugReportQrCode].vue?macro=true";
import { default as closeYAPsUyfGoXMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/close.vue?macro=true";
import { default as _91slug_93SwaNVyOUt1Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/frame/[slug].vue?macro=true";
import { default as _91hash_93FYSaYZAd96Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/i/[hash].vue?macro=true";
import { default as indexzEFMF6mku6Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/index.vue?macro=true";
import { default as infoWm2kN2YvBQMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/info.vue?macro=true";
import { default as indexbAuTOqYD8BMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/journey/index.vue?macro=true";
import { default as _91id_93jNfT6kflR8Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/journey/shared/[id].vue?macro=true";
import { default as legal5TqG9Ez3fFMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/legal.vue?macro=true";
import { default as _91hash_93C91IS0hgJ5Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/li/[hash].vue?macro=true";
import { default as _91sessionHash_93w3aRH8ONecMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/mock/channel/client/[installationPublicId]/[juaUserPublicId]/[juaUserToken]/[sessionHash].vue?macro=true";
import { default as _91installationToken_93EpM6693eouMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/mock/channel/installation/[installationPublicId]/[installationToken].vue?macro=true";
import { default as onboardingofKFXlVCGFMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/onboarding.vue?macro=true";
import { default as _91id_93iBKaP6A5B4Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/s/[id].vue?macro=true";
import { default as _91id_93OI0tKnJ4U5Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/scenes/[id].vue?macro=true";
import { default as settingsSSQkY8aAUtMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/settings.vue?macro=true";
import { default as setupm0hOsCzma8Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/setup.vue?macro=true";
import { default as component_45stubZZHGLCLi8IMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubZZHGLCLi8I } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3m/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91slug_93x27wRKIh4sMeta?.name ?? "locale-wg2-i-frame-slug",
    path: _91slug_93x27wRKIh4sMeta?.path ?? "/:locale()/wg2/i/frame/:slug()",
    meta: _91slug_93x27wRKIh4sMeta || {},
    alias: _91slug_93x27wRKIh4sMeta?.alias || [],
    redirect: _91slug_93x27wRKIh4sMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/i/frame/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexJh64adwDDGMeta?.name ?? "locale-wg2",
    path: indexJh64adwDDGMeta?.path ?? "/:locale()/wg2",
    meta: indexJh64adwDDGMeta || {},
    alias: indexJh64adwDDGMeta?.alias || [],
    redirect: indexJh64adwDDGMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/index.vue").then(m => m.default || m)
  },
  {
    name: indexKKivseUm8KMeta?.name ?? "locale-wg2-journey",
    path: indexKKivseUm8KMeta?.path ?? "/:locale()/wg2/journey",
    meta: indexKKivseUm8KMeta || {},
    alias: indexKKivseUm8KMeta?.alias || [],
    redirect: indexKKivseUm8KMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/journey/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mlQoBHsm6qMeta?.name ?? "locale-wg2-journey-shared-id",
    path: _91id_93mlQoBHsm6qMeta?.path ?? "/:locale()/wg2/journey/shared/:id()",
    meta: _91id_93mlQoBHsm6qMeta || {},
    alias: _91id_93mlQoBHsm6qMeta?.alias || [],
    redirect: _91id_93mlQoBHsm6qMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[locale]/wg2/journey/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9392ad5lSA4eMeta?.name ?? "slug",
    path: _91slug_9392ad5lSA4eMeta?.path ?? "/:slug()",
    meta: _91slug_9392ad5lSA4eMeta || {},
    alias: _91slug_9392ad5lSA4eMeta?.alias || [],
    redirect: _91slug_9392ad5lSA4eMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91bugReportQrCode_93AvNZXFv3gTMeta?.name ?? "bug_report-bugReportQrCode",
    path: _91bugReportQrCode_93AvNZXFv3gTMeta?.path ?? "/bug_report/:bugReportQrCode()",
    meta: _91bugReportQrCode_93AvNZXFv3gTMeta || {},
    alias: _91bugReportQrCode_93AvNZXFv3gTMeta?.alias || [],
    redirect: _91bugReportQrCode_93AvNZXFv3gTMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/bug_report/[bugReportQrCode].vue").then(m => m.default || m)
  },
  {
    name: closeYAPsUyfGoXMeta?.name ?? "close",
    path: closeYAPsUyfGoXMeta?.path ?? "/close",
    meta: closeYAPsUyfGoXMeta || {},
    alias: closeYAPsUyfGoXMeta?.alias || [],
    redirect: closeYAPsUyfGoXMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/close.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SwaNVyOUt1Meta?.name ?? "frame-slug",
    path: _91slug_93SwaNVyOUt1Meta?.path ?? "/frame/:slug()",
    meta: _91slug_93SwaNVyOUt1Meta || {},
    alias: _91slug_93SwaNVyOUt1Meta?.alias || [],
    redirect: _91slug_93SwaNVyOUt1Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/frame/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93FYSaYZAd96Meta?.name ?? "i-hash",
    path: _91hash_93FYSaYZAd96Meta?.path ?? "/i/:hash()",
    meta: _91hash_93FYSaYZAd96Meta || {},
    alias: _91hash_93FYSaYZAd96Meta?.alias || [],
    redirect: _91hash_93FYSaYZAd96Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/i/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexzEFMF6mku6Meta?.name ?? "index",
    path: indexzEFMF6mku6Meta?.path ?? "/",
    meta: indexzEFMF6mku6Meta || {},
    alias: indexzEFMF6mku6Meta?.alias || [],
    redirect: indexzEFMF6mku6Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infoWm2kN2YvBQMeta?.name ?? "info",
    path: infoWm2kN2YvBQMeta?.path ?? "/info",
    meta: infoWm2kN2YvBQMeta || {},
    alias: infoWm2kN2YvBQMeta?.alias || [],
    redirect: infoWm2kN2YvBQMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/info.vue").then(m => m.default || m)
  },
  {
    name: indexbAuTOqYD8BMeta?.name ?? "journey",
    path: indexbAuTOqYD8BMeta?.path ?? "/journey",
    meta: indexbAuTOqYD8BMeta || {},
    alias: indexbAuTOqYD8BMeta?.alias || [],
    redirect: indexbAuTOqYD8BMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/journey/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jNfT6kflR8Meta?.name ?? "journey-shared-id",
    path: _91id_93jNfT6kflR8Meta?.path ?? "/journey/shared/:id()",
    meta: _91id_93jNfT6kflR8Meta || {},
    alias: _91id_93jNfT6kflR8Meta?.alias || [],
    redirect: _91id_93jNfT6kflR8Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/journey/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: legal5TqG9Ez3fFMeta?.name ?? "legal",
    path: legal5TqG9Ez3fFMeta?.path ?? "/legal",
    meta: legal5TqG9Ez3fFMeta || {},
    alias: legal5TqG9Ez3fFMeta?.alias || [],
    redirect: legal5TqG9Ez3fFMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93C91IS0hgJ5Meta?.name ?? "li-hash",
    path: _91hash_93C91IS0hgJ5Meta?.path ?? "/li/:hash()",
    meta: _91hash_93C91IS0hgJ5Meta || {},
    alias: _91hash_93C91IS0hgJ5Meta?.alias || [],
    redirect: _91hash_93C91IS0hgJ5Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/li/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91sessionHash_93w3aRH8ONecMeta?.name ?? "mock-channel-client-installationPublicId-juaUserPublicId-juaUserToken-sessionHash",
    path: _91sessionHash_93w3aRH8ONecMeta?.path ?? "/mock/channel/client/:installationPublicId()/:juaUserPublicId()/:juaUserToken()/:sessionHash()",
    meta: _91sessionHash_93w3aRH8ONecMeta || {},
    alias: _91sessionHash_93w3aRH8ONecMeta?.alias || [],
    redirect: _91sessionHash_93w3aRH8ONecMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/mock/channel/client/[installationPublicId]/[juaUserPublicId]/[juaUserToken]/[sessionHash].vue").then(m => m.default || m)
  },
  {
    name: _91installationToken_93EpM6693eouMeta?.name ?? "mock-channel-installation-installationPublicId-installationToken",
    path: _91installationToken_93EpM6693eouMeta?.path ?? "/mock/channel/installation/:installationPublicId()/:installationToken()",
    meta: _91installationToken_93EpM6693eouMeta || {},
    alias: _91installationToken_93EpM6693eouMeta?.alias || [],
    redirect: _91installationToken_93EpM6693eouMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/mock/channel/installation/[installationPublicId]/[installationToken].vue").then(m => m.default || m)
  },
  {
    name: onboardingofKFXlVCGFMeta?.name ?? "onboarding",
    path: onboardingofKFXlVCGFMeta?.path ?? "/onboarding",
    meta: onboardingofKFXlVCGFMeta || {},
    alias: onboardingofKFXlVCGFMeta?.alias || [],
    redirect: onboardingofKFXlVCGFMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iBKaP6A5B4Meta?.name ?? "s-id",
    path: _91id_93iBKaP6A5B4Meta?.path ?? "/s/:id()",
    meta: _91id_93iBKaP6A5B4Meta || {},
    alias: _91id_93iBKaP6A5B4Meta?.alias || [],
    redirect: _91id_93iBKaP6A5B4Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OI0tKnJ4U5Meta?.name ?? "scenes-id",
    path: _91id_93OI0tKnJ4U5Meta?.path ?? "/scenes/:id()",
    meta: _91id_93OI0tKnJ4U5Meta || {},
    alias: _91id_93OI0tKnJ4U5Meta?.alias || [],
    redirect: _91id_93OI0tKnJ4U5Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/scenes/[id].vue").then(m => m.default || m)
  },
  {
    name: settingsSSQkY8aAUtMeta?.name ?? "settings",
    path: settingsSSQkY8aAUtMeta?.path ?? "/settings",
    meta: settingsSSQkY8aAUtMeta || {},
    alias: settingsSSQkY8aAUtMeta?.alias || [],
    redirect: settingsSSQkY8aAUtMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: setupm0hOsCzma8Meta?.name ?? "setup",
    path: setupm0hOsCzma8Meta?.path ?? "/setup",
    meta: setupm0hOsCzma8Meta || {},
    alias: setupm0hOsCzma8Meta?.alias || [],
    redirect: setupm0hOsCzma8Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3m/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: component_45stubZZHGLCLi8IMeta?.name ?? undefined,
    path: component_45stubZZHGLCLi8IMeta?.path ?? "/de/wg2",
    meta: component_45stubZZHGLCLi8IMeta || {},
    alias: component_45stubZZHGLCLi8IMeta?.alias || [],
    redirect: component_45stubZZHGLCLi8IMeta?.redirect,
    component: component_45stubZZHGLCLi8I
  }
]